@use '@mfe/shared-css/global' as *;

.modalContent {
  min-height: unset;
  button[data-cy='combined_actions_primary_action'] {
    flex-basis: unset;
  }
}

.mobilePadding {
  padding-bottom: $mobileTabBarHeight;
}
