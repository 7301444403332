@use '@mfe/shared-css/global' as *;

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.card {
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 30px 2px rgba(0, 0, 0, 0.15);

  @include query-below($smMax) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  @include query-above($mdMin) {
    width: 600px;
    max-height: 700px;
    margin: 64px 0;
  }

  @media screen and (max-height: 660px) and (orientation: landscape) {
    max-height: 90vh;
  }

  footer {
    border-radius: 0 0 16px 16px;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  margin: 34px 40px 64px 40px;
  align-items: center;
  text-align: center;
  @include screenSize('small') {
    margin: auto 16px auto 16px;
  }
}

.exit {
  margin: 20px 20px auto auto;
  background-color: white;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  cursor: pointer;
  padding-left: 4px;
  @include screenSize('small') {
    margin: 16px 16px 0 auto;
  }
  &:hover {
    background-color: color(gray_300);
  }
}

.footer {
  display: grid;
  gap: 8px;

  padding: 20px 16px;

  background-color: color(gray_100);

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;

    padding: 32px 40px;

    border-radius: 0 0 16px 16px;
  }
}

.buttonClear {
  border-radius: 20px;
  height: 40px;
  padding: 0 24px;
  background-color: color(gray_100);
  border: 1px solid black;
  margin-right: 16px;
  @include screenSize('small') {
    margin-right: 0;
  }
  :hover {
    cursor: pointer;
  }
}

.buttonBlue {
  border-radius: 20px;
  height: 40px;
  padding: 0 24px;
  background-color: color(teal_600);
  border: none;
  @include screenSize('small') {
    margin-bottom: 12px;
  }
  :hover {
    cursor: pointer;
  }
}
