@use '@mfe/shared-css/global' as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
}

.cardTerms {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: color(white);
  border-radius: 16px;
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 30px 2px rgba(0, 0, 0, 0.15);

  @include screenSize('medium') {
    position: sticky;
    height: auto;
    margin-top: auto;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    transform: translate(0, 0);
  }
}

.cardAddress {
  @extend .cardTerms;
  justify-content: center;
  @include screenSize('medium') {
    justify-self: normal;
  }
}

.cardMobileTerms {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: color(white);
  border-radius: 16px;
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 30px 2px rgba(0, 0, 0, 0.15);

  @include screenSize('small') {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    transform: translate(0, 0);
    height: 100%;
    max-width: none;
  }
}

.cardMobileAddress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: color(white);
  border-radius: 16px;
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 12px 30px 2px rgba(0, 0, 0, 0.15);

  @include screenSize('small') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    transform: translate(0, 0);
  }
}
