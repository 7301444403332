@use '@mfe/shared-css/global' as *;

.wrapper {
  padding: 24px 32px;

  @include query-below($smMax) {
    padding: 16px 24px;
  }

  @include query-below($xsMax) {
    padding-inline: 16px;
  }
}

.layout {
  display: grid;
  gap: 16px;
  grid-template-columns: 88px 1fr 88px;
  max-width: 1248px;
  margin: 40px auto;
  text-align: center;

  @include query-below($smMax) {
    margin: 0;
    grid-template-columns: 24px 1fr 24px;
  }

  a {
    display: inline-flex;
    align-items: center;
  }
}
