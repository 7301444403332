@use '@mfe/shared-css/global' as *;

.containerWhite {
  position: relative;

  padding: 24px;
  overflow: hidden;

  background-color: color(white);
  color: color(gray_800);
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  > * + * {
    margin-top: 24px;
  }

  @include screenSize('medium') {
    max-width: none;
  }

  @include screenSize('small') {
    border-radius: 0;
  }

  @include screenSize('extraSmall') {
    min-width: fit-content;
  }

  [class^='timeout-card'] {
    padding: 0;
  }

  [class*='usage-summary-header'] {
    border-bottom: 1px solid color(gray_200);
  }

  [class*='beam-empty-state__outer'] {
    display: flex;
    align-items: center;
    height: 424px;
    min-width: 320px;

    svg {
      color: color(black);
    }
    p {
      margin: 0;
    }
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    display: inline-block;
    width: 100%;
    height: 8px;

    background: linear-gradient(90deg, color(blue_700), color(teal_500));
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.changePlanDisabled {
  text-decoration: underline;
  cursor: not-allowed;
}

%customState {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 32px;
}

.voiceOnly {
  @extend %customState;
  padding-bottom: 48px;
  padding-top: 72px;
}

.preinstall {
  @extend %customState;
  padding-bottom: 48px;
  padding-top: 82px;
}

.disconnected {
  @extend %customState;
  padding-top: 96px;
  padding-bottom: 72px;
}

.usageButtonContainer {
  padding-top: 24px;
  border-top: 1px solid color(blue_800);
}

.planNameColumn {
  margin-right: 24px;
}

.remaining {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
