@use '@mfe/shared-css/global' as *;

.cancel-subscription-modal {
  :global {
    .beam-btn--primary {
      transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;

      &:hover {
        transform: scale(1.03);
        opacity: 0.9;
      }
    }
  }
}

.modal-body {
  text-align: center;
}

.mobilePadding {
  padding-bottom: $mobileTabBarHeight;
}
