/*
 * NOTE: YOU WILL NEED TO RUN `npm install` when you make changes to files in this folder 
 * in order for the @mfe/shared-css alias to work!
 */

@mixin query-above($value) {
  @media only screen and (min-width: $value) {
    @content;
  }
}

@mixin query-below($value) {
  @media only screen and (max-width: $value) {
    @content;
  }
}
