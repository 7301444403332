.layout {
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  gap: 16px;
}

.image {
  padding: 8px;

  svg {
    display: block;
    width: 24px;
    height: auto;
  }
}

.content {
  > * + * {
    margin-top: 8px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}
